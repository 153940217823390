import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createJWTClient,  } from '../../graphql/apollo';
import { ApolloProvider} from "react-apollo";
import { adopt } from 'react-adopt';
import moment from 'moment';
import { Mutation } from "react-apollo";
import * as firebase from "firebase/app";
// Add the Firebase services that you want to use
import "firebase/auth";
// import { Query, Mutation } from "react-apollo";
// import { getCampaignContact } from "../../graphql/queries";
import { updateContact } from "../../graphql/mutations";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDjyMhRoddPREn4KuhzSlrpb5anL02dPJs",
  authDomain: "saile-292a4.firebaseapp.com",
  databaseURL: "https://saile-292a4.firebaseio.com",
  projectId: "saile-292a4",
  storageBucket: "saile-292a4.appspot.com",
  messagingSenderId: "1014007777439",
  appId: "1:1014007777439:web:687772f114e61ea1a23aaa"
};
// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// const auth = firebase.auth();


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(5),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Unscubscribe(props) {
  debugger;
  const delisted_date = moment().format('YYYY-MM-DD');
  console.log("delisted_date: ", delisted_date)
  const [state, setState] = React.useState({
    TOKEN: null,
  });
  const classes = useStyles();
  console.log('props: ', props);
  if(state.TOKEN === null) {
    firebase.auth().signInAnonymously().then(item => {
      console.log('item.user.uid: ', item.user.uid)
      console.log('item: ', item)
    }).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log('errorCode: ', errorCode);
      console.log('errorMessage: ', errorMessage);
          // ...
    });
  }

  firebase.auth().onAuthStateChanged((firebaseUser) => {
    if (firebaseUser) {
      return firebaseUser.getIdToken().then((token) => firebase.auth().currentUser.getIdTokenResult()
        .then(async (result) => {
          // console.log('result: ', result);
          if (result.claims['https://hasura.io/jwt/claims']) {
            // console.log('claims token: ', token);
            if(state.TOKEN === null) {
              await setState({ ...state, TOKEN: token });
            }           
            return token
          }
          // console.log('token: ', token);
          const endpoint = 'https://us-central1-saile-292a4.cloudfunctions.net/refreshToken'
          return fetch(`${endpoint}?uid=${firebaseUser.uid}`).then((res) => {
            if (res.status === 200) {
              return firebaseUser.getIdToken(true)
            }
            return res.json().then((e) => { throw e })
          })
        })).then((validToken) => {
        // Store Token / Or create Apollo with your new token!
      }).catch(console.error)
    }
  })
  // console.log('props.match.params: ', props.match.params);
  const { company, contactEmail, contactId, token, campaign_id } = props.match.params
  console.log('token: ', token);
  console.log('campaign_id: ', campaign_id);
  console.log('contactId: ', contactId);
  const jwt_client = createJWTClient(token)
  // const jwt_client = createJWTClient(state.TOKEN)

  const Composed = adopt({
    contactMutation: ({ render }) => (
      <Mutation mutation={ updateContact } >
        { render }
      </Mutation>
    ),
  })

  return (
    <ApolloProvider client={jwt_client}>
      <Composed>
        {({ contactMutation, updateCampaignContactMutation }) => {
          return (
            <Container component="main" maxWidth="md" style={{ height: '100vh' }}>
              <CssBaseline />
              <div className={classes.paper}>
                <div className={classes.avatar}>
                    <Typography component="h1" variant="h2">Unsubscribe</Typography>
                </div>
                <Typography component="h1" variant="h5" style={{ textAlign: 'center', marginBottom: '10px' }}>
                You are trying to unsubscribe <strong>{contactEmail}</strong> from future communications from <strong>{company}</strong>.
                </Typography>
                <Typography component="h2" variant="h6">
                    Kindly click the button below to confirm.
                </Typography>
                <form className={classes.form} noValidate>
                  <Button
                    type="button"
                    fullWidth
                    onClick={async () => {

                      const contact = await contactMutation({
                        variables: {
                            objects: {
                                unsubscribed: true,
                            },
                            id: contactId
                        }
                      });
                      if (contact.data.update_contact && contact.data.update_contact.returning.length > 0 && contact.data.update_contact.returning[0].unsubscribed) {
                        props.history.push('/confirm-unsubscription', {contactEmail, company})
                      }                          
                    }}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Yes, Unsubscribe me
                  </Button>
                </form>
              </div>
            </Container>
          );}
        }
      </Composed>
    </ApolloProvider>
  );
}