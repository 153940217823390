import React, {useState} from 'react';
import EmailForm  from'./email_comp'
import { gql } from "@apollo/client";
import { ApolloProvider} from "react-apollo";
import { createJWTClient,  } from '../../graphql/apollo';
import SaileSun from '../../components/spinner'




const GET_DELIVERED = gql`
  query MyQuery($client_id: Int, $event_id: Int) {
    delivered(
      where: { client_id: { _eq: $client_id }, event_id: { _eq: $event_id } }
    ) {
      body
      client_id
      company
      contact_id
      created_at
      date
      digital_labor
      email
      employees
      event_id
      firstname
      label
      lastname
      previous_from
      previous_to
      revenue
      signature
      subject
      campaign_id
      client_email
      cc
      bot_email
    }
  }
`;



 export default function Mail(props) {
   const { client_id, event_id, token } = props.match.params;
   console.log("Mail client_id: ", client_id)
   console.log("Mail event_id: ", event_id)
   console.log("Mail token: ", token)
   const jwt_client = createJWTClient(token);
   console.log("Mail jwt_client: ", jwt_client)
   return (
     <>
       <ApolloProvider client={jwt_client}>
         <EmailProxyComp client={jwt_client} client_id={client_id} event_id={event_id} history={props.history} />
       </ApolloProvider>
     </>
   );
 }
 
 
 




 
 
 const EmailProxyComp = (props) => {
   console.log("EmailProxyComp props: ", props)
   const [state, setState] = useState({
     error: false,
     delivered: { contact_id :""},
     loading: true,
   });
  
  // const client = useApolloClient();

  props.client.query({
    query: GET_DELIVERED,
    variables: {
      client_id: props.client_id,
      event_id: props.event_id,
    },
  }).then((data)=>{
    console.log("data.data: ", data.data)
    if (data.data.delivered[0].contact_id !== state.delivered.contact_id) {
      console.log("same data:  ", state);
      const delivered = data.data.delivered[0];
      setState((prevState) => {
        return {
          ...prevState,
          delivered: delivered,
          loading: false,
        };
      });
    } 
  }).catch(err=>{
      console.log("err:  ", err);
      props.history.push("/not-found");
  })

  const {loading, delivered } =  state;
        return loading ? (
          <SaileSun />
        ) : (
          <EmailForm
            history={props.history}
            delivered={delivered}
            client_id={props.client_id}
            event_id={props.event_id}
          />
        );
}