import React from 'react';
import { Container,  Col, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import {
  TextInput,
  FormRow,
  Label,
} from "../../components/forms-utils";
import * as utils from '../../utils'
import { config }  from '../../utils'
import SaileSun from "../../components/spinner";


const { send_email_stage_lambda_api_endpoint } = utils[config]
let headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*'
};
const { ljust } = require('justify-text');


const getHeaders = () => {
  return {
      ...headers,
  };
};

const send_email = email => {
  return fetch(send_email_stage_lambda_api_endpoint, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(email, null, 4),
  });
}

const BodyField = styled.textarea`
    width: 100%;
    padding-left: 15px;
`


class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { from: "",
                   bccs: "",
                   subject:"",
                   body:"" ,
                   ccs:"",
                   toemail:"",
                   files:[],
                   inProgess: false,
                   show: false,
                        };

    this.handleChange = this.handleChange.bind(this);
    this.attachments = this.attachments.bind(this);
    this.submitHandler =  this.submitHandler.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    
  }
  
  handleChange (event){
    const { name, value } = event.target;
    this.setState({[name]: value});
  };
  
  toggleShow(){
    this.setState({
      show: !this.state.show
    })
  }
  componentDidMount(){
    const {client_id, event_id, delivered} = this.props;
    const deliveredInfo = delivered;


    console.log("PROPS:", { client_id, event_id, delivered });
    let body = `


      ${deliveredInfo.signature.trim()}

      ${deliveredInfo.body}
     `;
    body = this.format_body(body)
    this.setState({
      subject: deliveredInfo.subject || '',
      email: deliveredInfo.email || '',
      ccs: `${deliveredInfo.cc}${deliveredInfo.cc !== null && deliveredInfo.cc !== undefined && deliveredInfo.cc.length > 0 ? "," : ""} ${deliveredInfo.client_email}` || '',
      body,
      toemail: deliveredInfo.previous_from || '',
      bccs: '',
      // bccs: deliveredInfo.client_email || '',
      from: deliveredInfo.bot_email || '',
    });

  }

  attachments(file){
    this.setState({
      files: [...this.state.files, file]
    })
  }


  async submitHandler (e) {
    const { client_id, event_id, } = this.props;
    e.preventDefault()
    this.setState({
      inProgess: !this.state.inProgess
    })
    // axios({
    //   method: "post",
    //   url: "https://s9nbrc72ia.execute-api.us-west-2.amazonaws.com/Stage",
    //   data: {
    //    entity: {
    //     from_email: this.state.from,
    //     to_email: this.state.toemail,
    //     cc_email: this.state.ccs,
    //     bcc_email: this.state.bccs,
    //     subject: this.state.subject,
    //     body: this.state.body,
    //     client_id: client_id,
    //     event_id: event_id,
    //   }
    // },
    // })
    send_email({
      entity: {
       from_email: this.state.from,
       to_email: this.state.toemail,
       cc_email: this.state.ccs,
       bcc_email: this.state.bccs,
       subject: this.state.subject,
       body: this.state.body,
       client_id: client_id,
       event_id: event_id,
     }
   }).then((res)=>{
         this.setState({
           inProgess: !this.state.inProgess,
         });
         this.props.history.push("/convert-form/confirm");
      console.log("response: ", res)
    })
    .catch(err=>{
         this.setState({
           inProgess: !this.state.inProgess,
         });
         this.toggleShow();
        //  this.props.history.push("/delist/confirm");

      console.log("error: ", err, this.state)
    });

  }
  format_body = (body) => {
    body = ljust(body.replace(/\n\s*\n\s*\n/g, '\n\n').replace(/\*+/g, '').replace(/^\n\s*/g, '').replace(/^\n\t*/g, '').split("Unsubscribe from these emails")[0].replace (/^/,'\n\n\n\n\n'), 50, ' ');
    let lines = body.split("\n");
    for(var i=0; i<lines.length; i++) {
      lines[i] = lines[i].trim();
    }
    return lines.join("\n");
  }
  render() {
    const { bccs, from, subject, body, show, ccs, toemail, inProgess } = this.state;




    return inProgess ? (
      <SaileSun />
    ) : (
      <form id="email-form" onSubmit={this.submitHandler}>
        <Container style={{ height: "100vh" }}>
          <TextInput
            disable={true}
            id="from"
            type="text"
            name="from"
            placeholder="from"
            // defaultValue={from}
            forHtml="from"
            label="From"
            value={from}
            handleChange={this.handleChange}
          />

          <TextInput
            id="toemail"
            type="text"
            name="toemail"
            placeholder="to email"
            // defaultValue={toemail}
            forHtml="toemail"
            label="To Email"
            value={toemail}
            handleChange={this.handleChange}
          />

          <TextInput
            id="ccs"
            value={ccs}
            type="text"
            name="ccs"
            placeholder="CCs"
            forHtml="ccs"
            label="CCs"
            // defaultValue={ccs}
            handleChange={this.handleChange}
          />

          <TextInput
            id="bccs"
            type="text"
            name="bccs"
            value={bccs}
            placeholder="BCCs"
            forHtml="bccs"
            label="BCCs"
            // defaultValue={bccs}
            handleChange={this.handleChange}
          />

          <TextInput
            id="subject"
            type="text"
            name="subject"
            placeholder="subject"
            value={subject}
            forHtml="subject"
            label="Subject"
            // defaultValue={subject}
            handleChange={this.handleChange}
          />
          {/* <FormRow>
            <Col lg={1}>
              <Label>Attachment</Label>
            </Col>
            <Col lg={3}>
              <FileInput
                formSelector="email-form"
                attachmentChange={this.attachments}
              />
              <FileInput
                formSelector="email-form"
                attachmentChange={this.attachments}
              />
              <FileInput
                formSelector="email-form"
                attachmentChange={this.attachments}
              />
            </Col>
            <Col lg={8}></Col>
          </FormRow> */}

          <FormRow>
            <Col lg={1}>
              <Label>Body</Label>
            </Col>
            <Col lg={11}>
              <BodyField
                onChange={this.handleChange}
                value={body}
                name="body"
                // defaultValue={body}
                cols="6"
                rows="20"
              ></BodyField>
            </Col>
          </FormRow>
          <>
            <div style={{ paddingBottom: "30px" }}>
              <Button type="submit" size="lg" block>
                Send
              </Button>
            </div>
          </>
        </Container>

        <Modal
          show={show}
          onHide={this.toggleShow}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Email Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          Please check your information and try again
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleShow}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    );
  }
}



   


export default  EmailForm; 