import { intersection } from 'lodash';
const REACT_APP_REGION = process.env.REACT_APP_REGION !== null && process.env.REACT_APP_REGION !== undefined ? process.env.REACT_APP_REGION : process.env.REACT_APP_ENV === 'devops' ? 'us-east-1' : process.env.REACT_APP_ENV === 'prod' ? 'us-west-2' : 'us-east-2'
const REACT_APP_CLUSTER_NAME =  process.env.REACT_APP_CLUSTER_NAME !== null && process.env.REACT_APP_CLUSTER_NAME !== undefined ? process.env.REACT_APP_CLUSTER_NAME : process.env.REACT_APP_ENV === 'devops' ? 'dev' : process.env.REACT_APP_ENV === 'prod' ? 'prod' : 'test'
const REACT_APP_TYPE_PROJECT =  process.env.REACT_APP_TYPE_PROJECT !== null && process.env.REACT_APP_TYPE_PROJECT !== undefined ? process.env.REACT_APP_TYPE_PROJECT : process.env.REACT_APP_ENV === 'devops' ? 'dev' : process.env.REACT_APP_ENV === 'prod' ? 'prod' : 'test'
console.log('process.env.REACT_APP_ENV:  ', process.env.REACT_APP_ENV);
console.log('REACT_APP_REGION:  ', REACT_APP_REGION);
console.log('REACT_APP_CLUSTER_NAME:  ', REACT_APP_CLUSTER_NAME);
console.log('REACT_APP_TYPE_PROJECT:  ', REACT_APP_TYPE_PROJECT);
export const isArrayWithLength = (arr) => {
 return (Array.isArray(arr) && arr.length)
}
export const getAllowedRoutes = (routes) => {
 const roles = JSON.parse(sessionStorage.getItem('roles'));
 return routes.filter(({ permission }) => {
  if(!permission) return true;
  else if(!isArrayWithLength(permission)) return true;
  else return intersection(permission, roles).length;
 });
}

export const isAvailable = (admin, permission) => {
    return (admin && admin.user && admin.user.role && permission && permission.includes(admin.user.role))
}

// export const config =
//   process.env.NODE_ENV === 'development' &&
//   (process.env.REACT_APP_ENV === 'devops' || process.env.REACT_APP_ENV === undefined)
//     ? 'STAGE'
//     : process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'devops'
//     ? 'STAGE'
//     : process.env.NODE_ENV === 'production' &&
//       (process.env.REACT_APP_ENV === 'prod' ||
//         process.env.REACT_APP_ENV === undefined)
//     ? 'PROD'
//     : 'STAGE';

export const config = process.env.REACT_APP_ENV === 'devops'
    ? 'DEVOPS'
    : process.env.NODE_ENV === 'production' &&
      (process.env.REACT_APP_ENV === 'prod' ||
        process.env.REACT_APP_ENV === undefined)
    ? 'PROD'
    : 'STAGE'


console.log('config:  ', config);

export const STAGE = {
  actionable_opportunity_clarification_lambda_api_endpoint:
    `https://action-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  referral_clarification_lambda_api_endpoint:
    `https://referral-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  get_campaign_tasks_stage_lambda_api_endpoint:
    `https://taskstatus.${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  describe_service_stage_lambda_api_endpoint:
    `https://describeservice-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net`,
  update_service_stage_lambda_api_endpoint:
    `https://updateservice-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  deploy_campaign_stage_lambda_api_endpoint:
    `https://campaigndeploy-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  list_services_campaign_ids_stage_lambda_api_endpoint:
    `https://listservice-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  send_wind_request_lambda_api_endpoint: `https://sendwind-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  process_wind_response_api_endpoint: `https://apollowind-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  process_apollo_accounts_api_endpoint:
    `https://apolloaccounts-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  jwtauthendpoint: `https://sailev2-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  send_email_stage_lambda_api_endpoint: `https://sendmail-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  graphqlApiUri: `https://hasura-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/v1/graphql`,
  graphqlSocketUri: `wss://hasura-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/v1/graphql`,
  presignurl_endpoint: `https://presignurl-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  salesforce_prod_lambda_api_endpoint: `https://salesforceintegration-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  hubspot_prod_lambda_api_endpoint: `https://hunspotintegration-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`
};
export const PROD = {
  actionable_opportunity_clarification_lambda_api_endpoint:
    `https://action-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  referral_clarification_lambda_api_endpoint:
    `https://referral-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  get_campaign_tasks_stage_lambda_api_endpoint:
    `https://taskstatus.${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  describe_service_stage_lambda_api_endpoint:
    `https://describeservice-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net`,
  update_service_stage_lambda_api_endpoint:
    `https://updateservice-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  deploy_campaign_stage_lambda_api_endpoint:
    `https://campaigndeploy-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  list_services_campaign_ids_stage_lambda_api_endpoint:
    `https://listservice-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  send_wind_request_lambda_api_endpoint:
    `https://sendwind-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  process_wind_response_api_endpoint:
    `https://apollowind-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  process_apollo_accounts_api_endpoint:
    `https://apolloaccounts-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  jwtauthendpoint: `https://sailev2-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  send_email_stage_lambda_api_endpoint:
    `https://sendmail-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  graphqlApiUri: `https://hasura-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/v1/graphql`,
  graphqlSocketUri: `wss://hasura-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/v1/graphql`,
  presignurl_endpoint: `https://presignurl-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  auto_scheduling_endpoint: "",
  salesforce_prod_lambda_api_endpoint: `https://salesforceintegration-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  hubspot_prod_lambda_api_endpoint: `https://hunspotintegration-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`
};
console.log('PROD:  ', PROD);

export const DEVOPS = {
  actionable_opportunity_clarification_lambda_api_endpoint:
    process.env.REACT_APP_ACTIONABLE && process.env.REACT_APP_ACTIONABLE.length > 25 ? process.env.REACT_APP_ACTIONABLE :`https://action-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  referral_clarification_lambda_api_endpoint:
    process.env.REACT_APP_REFERRAL_CLARIFICATION && process.env.REACT_APP_REFERRAL_CLARIFICATION.length > 25 ? process.env.REACT_APP_REFERRAL_CLARIFICATION :  `https://referral-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  get_campaign_tasks_stage_lambda_api_endpoint:
    process.env.REACT_APP_GET_CAMPAIGN_TASKS_STAGE &&  process.env.REACT_APP_GET_CAMPAIGN_TASKS_STAGE.length > 25 ? process.env.REACT_APP_GET_CAMPAIGN_TASKS_STAGE : `https://taskstatus.${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  describe_service_stage_lambda_api_endpoint:
    process.env.REACT_APP_DESCRIBE_SERVICE_STAGE && process.env.REACT_APP_DESCRIBE_SERVICE_STAGE.length > 25 ? process.env.REACT_APP_DESCRIBE_SERVICE_STAGE: `https://describeservice-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net`,
  update_service_stage_lambda_api_endpoint:
    process.env.REACT_APP_UPDATE_SERVICE_STAGE && process.env.REACT_APP_UPDATE_SERVICE_STAGE.length > 25 ? process.env.REACT_APP_UPDATE_SERVICE_STAGE : `https://updateservice-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  deploy_campaign_stage_lambda_api_endpoint:
    process.env.REACT_APP_DEPLOY_CAMPAIGN_STAGE && process.env.REACT_APP_DEPLOY_CAMPAIGN_STAGE.length > 25 ? process.env.REACT_APP_DEPLOY_CAMPAIGN_STAGE  : `https://campaigndeploy-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  list_services_campaign_ids_stage_lambda_api_endpoint:
    process.env.REACT_APP_LIST_SERVICES_CAMPAIGN_IDS_STAGE && process.env.REACT_APP_LIST_SERVICES_CAMPAIGN_IDS_STAGE.length > 25 ? process.env.REACT_APP_LIST_SERVICES_CAMPAIGN_IDS_STAGE  : `https://listservice-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  send_wind_request_lambda_api_endpoint:
    process.env.REACT_APP_SEND_WIND_REQUEST && process.env.REACT_APP_SEND_WIND_REQUEST.length > 25 ? process.env.REACT_APP_SEND_WIND_REQUEST : `https://sendwind-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  process_wind_response_api_endpoint: process.env.REACT_APP_PROCESS_WIND && process.env.REACT_APP_PROCESS_WIND.length > 25 ? process.env.REACT_APP_PROCESS_WIND : `https://apollowind-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  process_apollo_accounts_api_endpoint: process.env.REACT_APP_PROCESS_APOLLO && `https://apolloaccounts-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  jwtauthendpoint: process.env.REACT_APP_JWTAUTHENDPOINT && process.env.REACT_APP_JWTAUTHENDPOINT.length > 25 ? process.env.REACT_APP_JWTAUTHENDPOINT : `https://sailev2-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  send_email_stage_lambda_api_endpoint: process.env.REACT_APP_SEND_EMAIL_STAGE && `https://sendmail-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  graphqlApiUri: process.env.REACT_APP_GRAPHQLAPIURI && process.env.REACT_APP_GRAPHQLAPIURI.length> 25 ? process.env.REACT_APP_GRAPHQLAPIURI : `https://hasura-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/v1/graphql`,
  graphqlSocketUri: `wss://hasura-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/v1/graphql`,
  presignurl_endpoint: process.env.REACT_APP_PRESIGNURL && process.env.REACT_APP_PRESIGNURL.length>25 ? process.env.REACT_APP_PRESIGNURL: `https://presignurl-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/`,
  salesforce_prod_lambda_api_endpoint: process.env.REACT_SALESFORCEINTEGRATION && process.env.REACT_SALESFORCEINTEGRATION.length> 25 ? process.env.REACT_SALESFORCEINTEGRATION : `https://salesforceintegration-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/` ,
  hubspot_prod_lambda_api_endpoint: process.env.REACT_HUBSPOTFORCEINTEGRATION && process.env.REACT_HUBSPOTFORCEINTEGRATION.length> 25 ? process.env.REACT_HUBSPOTFORCEINTEGRATION : `https://hunspotintegration-${REACT_APP_CLUSTER_NAME}.saile-graph-api.net/` ,
  auto_scheduling_endpoint: ''
}
