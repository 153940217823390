import React from 'react';
import styled from 'styled-components'
import sun from '../../saile-sun.png';



const Page = styled.div`
  padding-top: 20px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: #008ff5;
`;
const MailConfirm = ({Message= "Email Sent"}) => {
  return (
    <Page>
      <img width="300px" height="300px" src={sun} alt="saile sun" />
      <h1 style={{ color: "white" }}>
        <strong>{Message} </strong>
      </h1>
    </Page>
  );
};


export default MailConfirm;