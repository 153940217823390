import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {createJWTClient,} from '../../graphql/apollo';
import {ApolloProvider, Mutation} from "react-apollo";
import {adopt} from 'react-adopt';
import moment from 'moment';
import {closeConversationMutation, updateCampaignContact} from "../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(5),
        // backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Unscubscribe(props) {

    const delisted_date = moment().format('YYYY-MM-DD');
    const [state, setState] = React.useState({
        TOKEN: null,
    });
    const classes = useStyles();

    const {company, contactEmail, contactId, campaignId, token} = props.match.params
    const jwt_client = createJWTClient(token)

    const Composed = adopt({
        updateCampaignContactMutation: ({render}) => (
            <Mutation mutation={updateCampaignContact}>
                {render}
            </Mutation>
        ),
        closeConversationMutation: ({ render }) => (
          <Mutation mutation={ closeConversationMutation } >
            { render }
          </Mutation>
        ),
    })

    return (
        <ApolloProvider client={jwt_client}>
            <Composed>
                {({updateCampaignContactMutation, closeConversationMutation}) => {

                    return (
                        <Container component="main" maxWidth="md" style={{height: '100vh'}}>
                            <CssBaseline/>
                            <div className={classes.paper}>
                                <div className={classes.avatar}>
                                    <Typography component="h1" variant="h2">Unsubscribe</Typography>
                                </div>
                                <Typography component="h1" variant="h5"
                                            style={{textAlign: 'center', marginBottom: '10px'}}>
                                    You are trying to unsubscribe <strong>{contactEmail}</strong> from future
                                    communications from <strong>{company}</strong>.
                                </Typography>
                                <Typography component="h2" variant="h6">
                                    Kindly click the button below to confirm.
                                </Typography>
                                <form className={classes.form} noValidate>
                                    <Button
                                        type="button"
                                        fullWidth
                                        onClick={async () => {

                                            try {

                                                const contact = await updateCampaignContactMutation({
                                                    variables: {
                                                        objects: {
                                                            status: 'OptOut',
                                                            is_delisted: true,
                                                            delisted_date: delisted_date,
                                                            removal_reason: 'OptOut',
                                                            removal_date: delisted_date,
                                                        },
                                                        contact_id: contactId,
                                                        campaign_id: campaignId
                                                    }
                                                });
                                                await closeConversationMutation({
                                                  variables: {
                                                    conversationId: contact.data.update_campaign_contact.returning[0].latest_conversation_id,
                                                    endedAt: new Date().toISOString()
                                                  }
                                                });

                                                if (contact.data.update_campaign_contact && contact.data.update_campaign_contact.returning.length > 0 && contact.data.update_campaign_contact.returning[0].is_delisted) {
                                                    props.history.push('/confirm-unsubscription', {contactEmail, company})
                                                }

                                            } catch (error) {
                                                console.error(error);
                                            }

                                        }}
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Yes, Unsubscribe me
                                    </Button>
                                </form>
                            </div>
                        </Container>
                    );
                }
                }
            </Composed>
        </ApolloProvider>
    );
}