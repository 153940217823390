
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../actions';
import Unsubscribe from './Unsubscribe';
import Delist from './Delist';
// import Home from './Home';
import Confirmation from './Unsubscribe/confirmation';
import SalesForceConfirmation from './Salesforce/confirmation';
import Salesforce from './Salesforce';
import Hubspot from './Hubspot'
import HubspotConfirmation from './Hubspot/confirmation'
import Mail from './Mail';
import  MailConfirm from "./MailConfirm";



function Routes() {

    return (
        
        <Switch >
            <Route exact path={`/unsubscribe/:company/:contactEmail/:contactId/:token`} component={Unsubscribe} />
            <Route exact path={`/delist/:company/:contactEmail/:contactId/:campaignId/:token`} component={Delist} />
            <Route exact path={`/salesforce/:client_id/:event_id/:contactEmail`} component={Salesforce} />
            <Route exact path={`/hubspot/:client_id/:event_id/:contactEmail`} component={Hubspot} />
            <Route exact path={`/confirm-unsubscription`} component={Confirmation} />
            <Route path={`/confirm-salesforce/:contactEmail`} component={SalesForceConfirmation} />
            <Route path={`/confirm-hubspot/:contactEmail`} component={HubspotConfirmation} />
            {/* <Route path={`/`} component={Home} /> */}

            <Route
            exact
            //   path={`/email`}
            path={`/convert-form/:client_id/:event_id/:token`}
            component={Mail}
            />

            <Route
            exact
            //   path={`/email`}
            path={`/convert-form/confirm`}
            component={MailConfirm}
            />
        </Switch>
    );
}

export default connect(
    state => ({
      admin: state.admin,
    }),
    actions
)(Routes);