import React from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import * as utils from '../../utils'
import { config }  from '../../utils'

const { salesforce_prod_lambda_api_endpoint } = utils[config]




let headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*'
};

const getHeaders = () => {
  return {
      ...headers,
  };
};


const save_salesforce = salesforce => {
  return fetch(salesforce_prod_lambda_api_endpoint, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(salesforce, null, 4)
  });
}

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(5),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Salesforce extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      show: false,
      client_id: "",
      event_id:"",
      contact_email: "",
    }

    this.handleChange = this.handleChange.bind(this);
    this.submitHandler =  this.submitHandler.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  
  handleChange(event){
    const {name, value} = event.target;
    this.setState({[name]: value});
  };

  toggleShow(){
    this.setState({
      show: !this.state.show
    })
  }

  componentDidMount(){
  
    const {client_id, event_id, contactEmail} = this.props.match.params;
    console.log("PROPS:", this.props)
    this.setState({
      client_id: client_id,
      event_id: event_id,
      contact_email: contactEmail,
    })
  }

  submitHandler =  async (e) => {
    e.preventDefault()
    this.setState({
      inProgress: !this.state.inProgress
    })

    save_salesforce({
      input: {
        client_id: this.state.client_id,
        event_id: this.state.event_id,
      }
    }).then((res)=>{
      console.log("response: ", res)
      this.setState({
        inProgress: !this.state.inProgress,
      });
      this.props.history.push(`/confirm-salesforce/${this.state.contact_email}`);
    
    })
    .catch(err=>{
      this.setState({
        inProgress: !this.state.inProgress,
      });
      this.toggleShow();
      console.log("error: ", err, this.state)
    })
  }

  
  render(){
    const {classes} = this.props;
  
  return (
            <Container component="main" maxWidth="md" style={{ height: '100vh' }}>
              <CssBaseline />
              <div className={classes.paper}>
                <div className={classes.avatar}>
                    <Typography component="h1" variant="h2">Save to the Salesforce CRM</Typography>
                </div>
                <Typography component="h1" variant="h5" style={{ textAlign: 'center', marginBottom: '10px' }}>
                You are trying to save Decision Maker, <strong>{this.state.contact_email}</strong> information to the Salesforce CRM.
                </Typography>
                <Typography component="h2" variant="h6">
                    Please confirm by clicking the button below.
                </Typography>
                  <div style={{ paddingTop: "30px" }}>
                  <Button
                    type="submit"
                    size = "lg"
                    variant="primary"
                    onClick= {this.submitHandler}
                  >
                    Save Info to Salesforce Contact
                  </Button>
                  </div>
              </div>
              <Modal
            show={this.state.show}
            onHide={this.toggleShow}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header closeButton>
              <Modal.Title>Salesforce Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            Please check your information and try again
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.toggleShow}>
                Close
              </Button>
            </Modal.Footer>
            </Modal>
            </Container>
  );

  }
}


export default  withStyles(styles, { withTheme: true })(Salesforce);
