import React from 'react';
import sun from '../saile-sun.png';
import './spin.css'



const SaileSun = (props) => {

    return (
      <div className="spin-display">
        <img
          className="sun-logo"
          width="300px"
          height="300px"
          src={sun}
          alt="saile sun"
        />
        <p>
          <strong>Getting Your Data...</strong>
        </p>
      </div>
    );};


export default SaileSun;