import React from "react";
import {  Row, Col } from "react-bootstrap";
import styled from "styled-components";



export const TextField = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  height: 40px;
  &:focus {
    border-left: solid 5px red;
    outline: none;
  }
`;

export const Label = styled.label`
  padding: 5px 5px 5px 0;
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
`;

export const FormRow = styled(Row)`
  border-top: solid 1px #ccc;
  padding-bottom: 5px;
`;

export function TextInput({ handleChange, label, ...props }) {
  return (
    <FormRow>
      <Col lg={1}>
        <Label {...props}> {label} </Label>
      </Col>
      <Col lg={11}>
        <TextField onChange={handleChange} {...props} />
      </Col>
    </FormRow>
  );
}





export function FileInput({ formSelector, attachmentChange }) {
  // function validFileType(file) {
  //   const fileTypes = ["image/jpeg", "image/pjpeg", "image/png"];
  //   return fileTypes.includes(file.type);
  // }

  
  // let form;
  // useEffect(() => {
  //   form = document.getElementById(formSelector);
  // });

  const fileHandler = ({ target }) => {
    // console.log("from...", form);
    // var formdata = new FormData(form);
    const [file] = target.files;
    // const fileUrl = target.value;



    var reader = new FileReader();

    reader.onload = function () {
      console.log("results>>",reader.result);
      
      // console.log("type", file.type, "name", file.name, reader.result);

      const myfile = {
        name: file.name,
        type: file.type,
        content: reader.result
      };

      attachmentChange(myfile);

    }
    
    reader.readAsText(file);


  };   

  return (
    <input
      onChange={fileHandler}
      type="file"
      name="photo"
    />
  );
}